import React, { Component, cloneElement } from "react";
import { findDOMNode } from "react-dom";
import PropTypes from "prop-types";
import cs from "classnames";

export { default as Header } from "./Header";
export { default as Content } from "./Content";
export { default as Footter } from "./Footter";
export { default as BaseModalComponent } from "./BaseModalComponent";

export default class Modal extends Component {
    handleClose = (e) => {
        const modalElement = findDOMNode(this.modal);
        if (!modalElement) {
            return;
        }
        const { actions } = this.props;
        if (e.target !== modalElement && !modalElement.contains(e.target)) {
            if (this.content !== undefined && this.content !== null) {
                if (typeof this.content.onClose === "function") {
                    actions.closeModal(this.content.onClose);
                    return;
                }
            }

            // actions.closeModal();
        }
    };

    componentDidMount() {
        window.addEventListener("keydown", this.handleEsc);
    }

    componentWillUnmount() {
        window.removeEventListener("keydown", this.handleEsc);
    }

    handleEsc = (e) => {
        if (e.keyCode === 27) {
            this.handleClose(e);
        }
    };

    modifyContent() {
        const { content, isFixed } = this.props;
        const { className, ...props } = content.props;
        if (content === undefined) {
            return content;
        }
        const css = {
            "H(100%) Pos(r)": isFixed
        };
        const newContent = cloneElement(content, {
            ...props,
            className: cs(css, className),
            ref: (ref) => (this.content = ref)
        });
        return newContent;
    }

    getChildContext() {
        const { isFixed, fullScreen, size } = this.props;

        return {
            $modalContainer: {
                isFixed,
                fullScreen,
                size
            }
        };
    }

    render() {
        const {
            className,
            close,
            children,
            isFixed,
            content = null,
            fullScreen,
            size,
            ...props
        } = this.props;

        if (content === null) {
            document.body.style.overflowY = "";
            return "";
        }

        document.body.style.overflowY = "hidden";

        const overlayCss = {
            "StretchedBox Bg(overlay) Z(9000)": true,
            "Pos(f)!": true,
            "Pt(80px)--sm": !fullScreen,
            "Ov(a)": !isFixed,
            "Pb(80px)--sm": isFixed && !fullScreen,
            "Pstart(16px)--sm": !fullScreen,
            "Pend(16px)--sm": !fullScreen,
            "Pstart(24px)--lg": !fullScreen,
            "Pend(24px)--lg": !fullScreen
        };

        const boxCss = {
            "H(100%)": isFixed,
            "W(100%)": true,
            // "W(80%)--md W(70%)--lg W(60%)--xl": !fullScreen,
            "Maw(360px)": size === "xs" && !fullScreen,
            "Maw(560px)": size === "sm" && !fullScreen,
            "Maw(720px)": size === "md" && !fullScreen,
            "Maw(960px)": size === "lg" && !fullScreen,
            "Maw(1200px)": size === "xl" && !fullScreen,
            "Bdrs(0px)": true,
            "Bdrs(radius)--sm": size === "xs" || size === "sm",
            "Bdrs(radius-2)--sm": size === "md" || size === "lg" || size === "xl"
        };

        const modifyContent = this.modifyContent();

        return (
            <div
                className={cs(overlayCss, className)}
                {...props}
                onMouseDown={this.handleClose}
            >
                <div
                    className={cs("Bxsh(deep4) My(0) Mx(a) Pos(r) Ov(h)", boxCss)}
                    ref={(ref) => (this.modal = ref)}
                >
                    {modifyContent}
                </div>
            </div>
        );
    }
}

Modal.propTypes = {
    actions: PropTypes.shape({
        addActionButton: PropTypes.func.isRequired,
        closeModal: PropTypes.func.isRequired,
        openModal: PropTypes.func.isRequired,
        updateConfigs: PropTypes.func.isRequired,
        updateDescription: PropTypes.func.isRequired,
        updateDuration: PropTypes.func.isRequired,
        updateName: PropTypes.func.isRequired
    }).isRequired,
    content: PropTypes.element,
    isFixed: PropTypes.bool,
    fullScreen: PropTypes.bool,
    size: PropTypes.string
};

Modal.defaultProps = {
    isFixed: true,
    fullScreen: false,
    size: ""
};

Modal.childContextTypes = {
    $modalContainer: PropTypes.object.isRequired
};
